<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div v-if="!isLoading">
    <v-card-text>
      <v-row>
        <v-col
          v-if="(permisos.includes('contrate_hotels:create') || permisos.includes('*')) && !offline"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>
        <v-col
          v-if="permisos.includes('contrate_hotels:create') && !offline"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            outlined
            @click="uploadLote"
          >
            <v-icon>{{ icons.mdiCloudUpload }}</v-icon>
            <span class="pl-2">{{ $t('btn.uploadLote') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="model.proveedor_id"
            :items="itemsProveedor"
            :search-input.sync="searchProveedor"
            hide-details
            hide-selected
            :label="$t('lbl.proveedor')"
            outlined
            dense
            item-text="name_comercial"
            item-value="id"
            @change="load"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.proveedor') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name_comercial"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="model.identificador"
            :label="$t('lbl.identificador')"
            hide-details
            dense
            outlined
            @input="load"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="model.destino"
            :items="itemsDestino"
            :search-input.sync="searchDestino"
            hide-details
            hide-selected
            :label="$t('lbl.destino')"
            outlined
            dense
            clearable
            @change="load"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.destino') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="model.state"
            :items="states"
            :label="$t('lbl.state')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="value"
            @change="load"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="model.proveedor_id || model.identificador || model.destino || model.state"
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch()"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="text-center"
        >
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('lbl.contratos') }}
        </v-col>
      </v-row>
    </v-form>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.proveedor') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.operador') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.identificador') }}
            </th>
            <th
              class="text-uppercase"
              style="text-align: center;"
            >
              {{ $t('lbl.currency') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.destino') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.state') }}
            </th>
            <th
              v-if="
                permisos.includes('contrate_hotels:edit') ||
                  permisos.includes('contrate_hotels:destroy') ||
                  permisos.includes('*')
              "
              class="text-uppercase"
              width="200px"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>
              <!--<span v-if="iten.proveedor_id != null">
                {{ iten.proveedor.name_comercial }}
              </span>-->
              <span v-if="user.nivel <= 0">
                <span v-if="iten.operador === 1111">
                  <span v-if="iten.proveedor_id != null">
                    {{ iten.proveedor.name_comercial }}
                  </span>
                </span>
                <span v-else>
                  {{ iten.operador }}
                </span>
              </span>
              <span v-else>
                <span v-if="iten.proveedor_id != null">
                  {{ iten.proveedor.name_comercial }}
                </span>
              </span>
            </td>
            <td>
              <span v-if="iten.operators.length === 1">
                {{ iten.operators[0] }}
              </span>
              <span v-if="iten.operators.length > 1">
                <v-tooltip
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      style="cursor: pointer;"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Varios
                    </span>
                  </template>
                  <span>
                    <span
                      v-for="(operator, indO) in iten.operators"
                      :key="indO"
                    > {{ operator }} <br /> </span>
                  </span>
                </v-tooltip>
              </span>
            </td>
            <td>{{ iten.identificador }}</td>
            <td style="text-align: center;">
              <span v-if="iten.currency_id">
                {{ iten.currency.code }}
              </span>
            </td>
            <td>{{ iten.destino }}</td>
            <td>
              <span v-if="iten.publicado && !iten.cancelado && !iten.vencido">
                <v-tooltip
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('lbl.active') }}</span>
                </v-tooltip>
              </span>
              <span v-else-if="!iten.publicado && !iten.cancelado && !iten.vencido">
                <v-tooltip
                  top
                  color="error"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error">mdi-currency-usd-off</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('lbl.stopSale') }}</span>
                </v-tooltip>
              </span>
              <span v-else-if="iten.cancelado">
                <v-tooltip
                  top
                  color="error"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('lbl.canceled') }}</span>
                </v-tooltip>
              </span>
              <span v-else-if="iten.vencido">
                <v-tooltip
                  top
                  color="error"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="error">mdi-watch-vibrate-off</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('lbl.vencido') }}</span>
                </v-tooltip>
              </span>
            </td>
            <!--<td>
              <span v-if="iten.operador_id != null">
                {{ iten.operador.name }}
              </span>
            </td>-->
            <td
              v-if="
                permisos.includes('contrate_hotels:list') ||
                  permisos.includes('contrate_hotels:edit') ||
                  permisos.includes('contrate_hotels:destroy') ||
                  permisos.includes('*')
              "
            >
              <!--<v-tooltip
                v-if="permisos.includes('contrate_hotels:edit') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="season(iten)"
                  >
                    <v-icon>{{ icons.mdiCalendarRange }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('lbl.temporadas') }}</span>
              </v-tooltip>
              <v-tooltip
                v-if="permisos.includes('contrate_hotels:edit') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="prices(iten)"
                  >
                    <v-icon>{{ icons.mdiCashMultiple }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('lbl.prices') }}</span>
              </v-tooltip>-->
              <v-tooltip
                v-if="
                  (permisos.includes('contrate_hotels:edit') || permisos.includes('*')) && iten.nivel === user.nivel
                "
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(iten)"
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.edit') }}</span>
              </v-tooltip>
              <v-tooltip
                v-if="permisos.includes('contrate_hotels:list') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="seeItem(iten)"
                  >
                    <v-icon>{{ icons.mdiEye }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.see') }}</span>
              </v-tooltip>
              <v-tooltip
                v-if="
                  (permisos.includes('contrate_hotels:destroy') || permisos.includes('*')) && iten.nivel === user.nivel
                "
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteContrateItem(iten)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- MOSTRAR DELETE CONTRATE-->
    <v-dialog
      v-model="isDialogVisibleDeleteContrate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteContrate') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDeleteContrate = !isDialogVisibleDeleteContrate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDeleteContrate()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiEyePlus,
  mdiMenuRight,
  mdiMenuLeft,
  mdiMenuDown,
  mdiEraserVariant,
  mdiCashMultiple,
  mdiCalendarRange,
  mdiCloudUpload,
  mdiEye,
} from '@mdi/js'

export default {
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      states: [],
      totalItems: 0,
      totalF: 0,
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiEyePlus,
        mdiMenuRight,
        mdiMenuLeft,
        mdiMenuDown,
        mdiEraserVariant,
        mdiCashMultiple,
        mdiCalendarRange,
        mdiCloudUpload,
        mdiEye,
      },
      destinos: [],
      itemsDestino: [],
      searchDestino: null,
      rentadoras: [],
      showRow: false,
      indexRow: -1,
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      isDialogVisibleDeleteContrate: false,
      item: {},
      itemId: null,
      itemPosTarifa: null,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),

      model: {
        proveedor_id: null,
        destino: null,
        identificador: null,
        state: null,
      },
      aerolineas: [],
      itemsAerolinea: [],
      numeros_vuelos: [],
      semanas: [],
      itemsRentadora: [],
      searchRentadora: null,
      tipoContrato: 'categoria',
      rules: {
        required: value => !!value || 'Required.',
        // eslint-disable-next-line no-restricted-globals
        numeric: value => !isNaN(value) || 'Invalid value.',
        counter: value => parseInt(value, 10) <= 100 || 'Number between 1 and 100',
      },
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      tipos_contratos: [],
      precios: [],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      isLoading: true,
      user: {},
      offline: false,
    }
  },
  watch: {
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
    searchDestino(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDestino(val.toLowerCase())
      } else {
        this.itemsDestino = []
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState({
      pricesTemporadasContrateCar: state => state.app.pricesTemporadasContrateCar,
    }),
  },
  created() {
    this.profile()
    this.getDestinos()
    this.getProveedores()
    this.loadAll()
    this.load()

    this.tipos_contratos.push({
      name: this.$t('lbl.cat'),
      slug: 'categoria',
    })
    this.tipos_contratos.push({
      name: this.$t('lbl.brandModel'),
      slug: 'marca_modelo',
    })

    this.precios.push({
      name: this.$t('lbl.firstDay'),
      value: 1,
    })
    this.precios.push({
      name: this.$t('lbl.dayByDay'),
      value: 2,
    })

    this.states.push({
      name: this.$t('lbl.active'),
      value: 'active',
    })
    this.states.push({
      name: this.$t('lbl.stopSale'),
      value: 'stopSale',
    })
    this.states.push({
      name: this.$t('lbl.canceled'),
      value: 'canceled',
    })
    this.states.push({
      name: this.$t('lbl.vencido'),
      value: 'vencido',
    })
  },
  methods: {
    ...mapMutations([
      'updateCategoryContrateCar',
      'updateMarcaModeloContrateCar',
      'updateTemporadaContrateCar',
      'updatePricesTemporadasContrateCar',
      'updateSuplementoContrateCar',
    ]),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
          if (this.user.company_id) {
            if (this.user.company) {
              if (this.user.company.offline) {
                this.offline = this.user.company.offline
              }
            }
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isLoading = false
        })
    },
    getProveedores() {
      this.axios
        .get('afiliados/byproduct/cars', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
    },
    getDestinos() {
      this.axios
        .get('destinos-hotels-filter-product?field=country', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.destinos = res.data.data.data
        })
    },
    filterDestino(v) {
      this.itemsDestino = []
      if (v === '') {
        this.itemsDestino = []
      } else {
        this.itemsDestino = this.destinos.filter(e => e.toLowerCase())
      }
    },
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    load() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,

        search: this.model,
        perfil_slug: sessionStorage.getItem('perfil'),
      }
      this.axios
        .post('contrate_hotels/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // console.log(res)
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            // this.items = res.data.data
            // PARA ORDERNAR BY PROVEEDOR
            this.items = res.data.data.sort((a, b) => {
              let OrderA = a.identificador
              if (this.user.nivel <= 0) {
                if (a.operador === 1111) {
                  OrderA = a.proveedor.name_comercial
                } else {
                  OrderA = a.operador
                }
              } else {
                OrderA = a.proveedor.name_comercial
              }

              let OrderB = b.identificador
              if (this.user.nivel <= 0) {
                if (b.operador === 1111) {
                  OrderB = b.proveedor.name_comercial
                } else {
                  OrderB = b.operador
                }
              } else {
                OrderB = b.proveedor.name_comercial
              }

              const x = OrderA
              const y = OrderB

              if (x > y) {
                return 1
              }
              if (x < y) {
                return -1
              }

              return 0
            })
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    searchAll() {
      this.pagination.current = 1
      this.load()
    },
    loadAll() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        perfil_slug: sessionStorage.getItem('perfil'),
      }
      this.axios
        .post('contrate_hotels/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // console.log(res)
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.iaerolinea_idsDialogVisible
    },
    newItem() {
      sessionStorage.removeItem('hotels-contrate-id')
      sessionStorage.removeItem('contrate-hotels-show')

      this.$router.push({ name: 'hotels-contrate-create' })
    },
    editItem(iten) {
      // this.$store.state.app.loadEscalas = true
      sessionStorage.setItem('hotels-contrate-id', iten.id)
      sessionStorage.removeItem('contrate-hotels-show')

      this.$router.push({ name: 'hotels-contrate-update' })
    },
    seeItem(iten) {
      sessionStorage.setItem('hotels-contrate-id', iten.id)
      sessionStorage.setItem('contrate-hotels-show', true)

      this.$router.push({ name: 'hotels-contrate-update' })
    },
    season(iten) {
      sessionStorage.setItem('hotels-contrate-id', iten.id)

      this.$router.push({ name: 'hotels-contrate-seasons' })
    },
    prices(iten) {
      sessionStorage.setItem('hotels-contrate-id', iten.id)

      this.$router.push({ name: 'hotels-contrate-prices' })
    },
    cloneItem(iten, posTarifa) {
      // console.log(iten)
      this.$store.state.app.loadEscalas = true
      sessionStorage.setItem('flight-contrate-id', iten.id)
      sessionStorage.setItem('flight-contrate-pos-tarifa', posTarifa)

      sessionStorage.removeItem('flight-contrate-tarifa-edit')

      sessionStorage.setItem('flight-aerolinea-id', iten.flight.aerolinea_id)
      sessionStorage.setItem('flight-numero-vuelo', iten.flight.numero_de_vuelo)

      this.$router.push({ name: 'flights-contrate-update' })
    },
    reseat() {
      this.isDialogVisibleDelete = false
      this.isDialogVisibleDeleteContrate = false
      this.item = {}
      this.itemId = null
      this.itemPosTarifa = null
      this.loading = false
      this.load()
    },
    menorPrice(tarifas, type) {
      const prices = []
      if (type === 'price_adult') {
        tarifas.forEach(element => {
          prices.push(element.price_adult)
        })
      } else if (type === 'price_child') {
        tarifas.forEach(element => {
          prices.push(element.price_child)
        })
      }

      return this.sortedArray(prices)[0]
    },
    sortedArray(arrays) {
      return arrays.sort((a, b) => a - b)
    },
    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
    uploadLote() {
      sessionStorage.removeItem('contrate-hotels-id')
      sessionStorage.removeItem('contrate-hotels-clone')
      sessionStorage.removeItem('contrate-hotels-show')
      this.$router.push({ name: 'contrate-hotels-upload-lote' })
    },

    clearSearch() {
      this.model = {
        proveedor_id: null,
        identificador: null,
        destino: null,
        state: null,
      }
      this.load()
    },
    deleteContrateItem(iten) {
      this.isDialogVisibleDeleteContrate = true
      this.itemId = iten.id
    },
    confirmDeleteContrate() {
      this.axios
        .delete(`contrate_hotels/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
